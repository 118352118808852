import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0e433b93 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _cbd4fa14 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _3857e4fa = () => interopDefault(import('../pages/address.vue' /* webpackChunkName: "pages/address" */))
const _b11156da = () => interopDefault(import('../pages/buy-car.vue' /* webpackChunkName: "pages/buy-car" */))
const _6bc00e26 = () => interopDefault(import('../pages/certified.vue' /* webpackChunkName: "pages/certified" */))
const _16c44d4d = () => interopDefault(import('../pages/coming-soon.vue' /* webpackChunkName: "pages/coming-soon" */))
const _9dcb461c = () => interopDefault(import('../pages/compare-car.vue' /* webpackChunkName: "pages/compare-car" */))
const _e64111b4 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _09b28256 = () => interopDefault(import('../pages/detail-certified.vue' /* webpackChunkName: "pages/detail-certified" */))
const _41d65b9f = () => interopDefault(import('../pages/faq/index.vue' /* webpackChunkName: "pages/faq/index" */))
const _28f11594 = () => interopDefault(import('../pages/favorite-cars.vue' /* webpackChunkName: "pages/favorite-cars" */))
const _a36d9244 = () => interopDefault(import('../pages/inspection.vue' /* webpackChunkName: "pages/inspection" */))
const _6cc30cff = () => interopDefault(import('../pages/installment.vue' /* webpackChunkName: "pages/installment" */))
const _10ba8d22 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _1a8eea3b = () => interopDefault(import('../pages/manufacturer.vue' /* webpackChunkName: "pages/manufacturer" */))
const _3b011b86 = () => interopDefault(import('../pages/news.vue' /* webpackChunkName: "pages/news" */))
const _9bf3b8fa = () => interopDefault(import('../pages/news/carpla-events.vue' /* webpackChunkName: "pages/news/carpla-events" */))
const _0ce57e04 = () => interopDefault(import('../pages/news/news-categories.vue' /* webpackChunkName: "pages/news/news-categories" */))
const _27c6b3ea = () => interopDefault(import('../pages/news/result-search-news.vue' /* webpackChunkName: "pages/news/result-search-news" */))
const _0ad81490 = () => interopDefault(import('../pages/news/_slug.vue' /* webpackChunkName: "pages/news/_slug" */))
const _5172b96e = () => interopDefault(import('../pages/notifications.vue' /* webpackChunkName: "pages/notifications" */))
const _c24eef7a = () => interopDefault(import('../pages/register-success.vue' /* webpackChunkName: "pages/register-success" */))
const _46ec5cbe = () => interopDefault(import('../pages/reviews.vue' /* webpackChunkName: "pages/reviews" */))
const _52fb02db = () => interopDefault(import('../pages/sales-policies.vue' /* webpackChunkName: "pages/sales-policies" */))
const _b094f14c = () => interopDefault(import('../pages/search-car-v2-result.vue' /* webpackChunkName: "pages/search-car-v2-result" */))
const _143cea60 = () => interopDefault(import('../pages/search-car-v2.vue' /* webpackChunkName: "pages/search-car-v2" */))
const _d43acbce = () => interopDefault(import('../pages/search-car.vue' /* webpackChunkName: "pages/search-car" */))
const _100e737a = () => interopDefault(import('../pages/sell-car.vue' /* webpackChunkName: "pages/sell-car" */))
const _1110ee7a = () => interopDefault(import('../pages/sell-car/Step2.vue' /* webpackChunkName: "pages/sell-car/Step2" */))
const _111f05fb = () => interopDefault(import('../pages/sell-car/Step3.vue' /* webpackChunkName: "pages/sell-car/Step3" */))
const _07f8a58a = () => interopDefault(import('../pages/sitemap.vue' /* webpackChunkName: "pages/sitemap" */))
const _3c0a7b8c = () => interopDefault(import('../pages/specifications.vue' /* webpackChunkName: "pages/specifications" */))
const _34199a5f = () => interopDefault(import('../pages/thankyou.vue' /* webpackChunkName: "pages/thankyou" */))
const _1774d160 = () => interopDefault(import('../pages/upgrade-car-v2.vue' /* webpackChunkName: "pages/upgrade-car-v2" */))
const _00b29329 = () => interopDefault(import('../pages/upgrade-car.vue' /* webpackChunkName: "pages/upgrade-car" */))
const _300b7666 = () => interopDefault(import('../pages/account/car-purchase.vue' /* webpackChunkName: "pages/account/car-purchase" */))
const _e5f265a8 = () => interopDefault(import('../pages/account/car-sale.vue' /* webpackChunkName: "pages/account/car-sale" */))
const _b4387e48 = () => interopDefault(import('../pages/account/interested-car.vue' /* webpackChunkName: "pages/account/interested-car" */))
const _9c701ef8 = () => interopDefault(import('../pages/account/service-247/index.vue' /* webpackChunkName: "pages/account/service-247/index" */))
const _3fdcfe1b = () => interopDefault(import('../pages/auth/change-password.vue' /* webpackChunkName: "pages/auth/change-password" */))
const _43fe2188 = () => interopDefault(import('../pages/auth/forgot-password.vue' /* webpackChunkName: "pages/auth/forgot-password" */))
const _0a6dc7ca = () => interopDefault(import('../pages/auth/log-in.vue' /* webpackChunkName: "pages/auth/log-in" */))
const _19af5657 = () => interopDefault(import('../pages/auth/login-register/index.vue' /* webpackChunkName: "pages/auth/login-register/index" */))
const _22afcc46 = () => interopDefault(import('../pages/auth/oauth/index.vue' /* webpackChunkName: "pages/auth/oauth/index" */))
const _0fdb26a6 = () => interopDefault(import('../pages/auth/reset-password.vue' /* webpackChunkName: "pages/auth/reset-password" */))
const _7ee727a4 = () => interopDefault(import('../pages/auth/sign-up.vue' /* webpackChunkName: "pages/auth/sign-up" */))
const _63bcb34a = () => interopDefault(import('../pages/blog/list-product-suggest.vue' /* webpackChunkName: "pages/blog/list-product-suggest" */))
const _2b2e4904 = () => interopDefault(import('../pages/blog/product-item.vue' /* webpackChunkName: "pages/blog/product-item" */))
const _04177b32 = () => interopDefault(import('../pages/car-manufacturer/car-brand-year.vue' /* webpackChunkName: "pages/car-manufacturer/car-brand-year" */))
const _1308cc93 = () => interopDefault(import('../pages/car-manufacturer/car-brand.vue' /* webpackChunkName: "pages/car-manufacturer/car-brand" */))
const _35a8fff0 = () => interopDefault(import('../pages/car-manufacturer/car-figure.vue' /* webpackChunkName: "pages/car-manufacturer/car-figure" */))
const _9e6dc8e2 = () => interopDefault(import('../pages/cars/Dialog360Interior.vue' /* webpackChunkName: "pages/cars/Dialog360Interior" */))
const _0fb6f0d8 = () => interopDefault(import('../pages/cars/DialogCallMe.vue' /* webpackChunkName: "pages/cars/DialogCallMe" */))
const _3e75489a = () => interopDefault(import('../pages/cars/DialogComingSoon.vue' /* webpackChunkName: "pages/cars/DialogComingSoon" */))
const _f331b758 = () => interopDefault(import('../pages/cars/DialogDepositCar.vue' /* webpackChunkName: "pages/cars/DialogDepositCar" */))
const _77d9d4b2 = () => interopDefault(import('../pages/cars/DialogInfoOrder.vue' /* webpackChunkName: "pages/cars/DialogInfoOrder" */))
const _3e3758c6 = () => interopDefault(import('../pages/cars/DialogPhoneAndMailCar.vue' /* webpackChunkName: "pages/cars/DialogPhoneAndMailCar" */))
const _a77aa7fe = () => interopDefault(import('../pages/cars/DialogPromo.vue' /* webpackChunkName: "pages/cars/DialogPromo" */))
const _7da84ad1 = () => interopDefault(import('../pages/cars/DialogShare.vue' /* webpackChunkName: "pages/cars/DialogShare" */))
const _6fcdf732 = () => interopDefault(import('../pages/cars/DialogSimilarCar.vue' /* webpackChunkName: "pages/cars/DialogSimilarCar" */))
const _e5bbacec = () => interopDefault(import('../pages/cars/DialogTestDrive.vue' /* webpackChunkName: "pages/cars/DialogTestDrive" */))
const _64285e2c = () => interopDefault(import('../pages/cars/Gallery.vue' /* webpackChunkName: "pages/cars/Gallery" */))
const _6f0b149a = () => interopDefault(import('../pages/insurance/Component1.vue' /* webpackChunkName: "pages/insurance/Component1" */))
const _46cfbb0d = () => interopDefault(import('../pages/insurance/Component10.vue' /* webpackChunkName: "pages/insurance/Component10" */))
const _6eeee598 = () => interopDefault(import('../pages/insurance/Component2.vue' /* webpackChunkName: "pages/insurance/Component2" */))
const _6ed2b696 = () => interopDefault(import('../pages/insurance/Component3.vue' /* webpackChunkName: "pages/insurance/Component3" */))
const _6eb68794 = () => interopDefault(import('../pages/insurance/Component4.vue' /* webpackChunkName: "pages/insurance/Component4" */))
const _6e9a5892 = () => interopDefault(import('../pages/insurance/Component5.vue' /* webpackChunkName: "pages/insurance/Component5" */))
const _6e7e2990 = () => interopDefault(import('../pages/insurance/Component6.vue' /* webpackChunkName: "pages/insurance/Component6" */))
const _6e61fa8e = () => interopDefault(import('../pages/insurance/Component7.vue' /* webpackChunkName: "pages/insurance/Component7" */))
const _6e45cb8c = () => interopDefault(import('../pages/insurance/Component8.vue' /* webpackChunkName: "pages/insurance/Component8" */))
const _74447d6b = () => interopDefault(import('../pages/insurance/insurance.vue' /* webpackChunkName: "pages/insurance/insurance" */))
const _358b41a2 = () => interopDefault(import('../pages/insurance/RatingItem.vue' /* webpackChunkName: "pages/insurance/RatingItem" */))
const _58e81d70 = () => interopDefault(import('../pages/recruit/DialogSuccessApplication.vue' /* webpackChunkName: "pages/recruit/DialogSuccessApplication" */))
const _1616781a = () => interopDefault(import('../pages/recruit/recruit.vue' /* webpackChunkName: "pages/recruit/recruit" */))
const _48cbae68 = () => interopDefault(import('../pages/service/RSA.vue' /* webpackChunkName: "pages/service/RSA" */))
const _63aee951 = () => interopDefault(import('../pages/account/service-247/DialogRequestAssistance.vue' /* webpackChunkName: "pages/account/service-247/DialogRequestAssistance" */))
const _63b1c21e = () => interopDefault(import('../pages/account/service-247/Service247Item.vue' /* webpackChunkName: "pages/account/service-247/Service247Item" */))
const _d261c120 = () => interopDefault(import('../pages/auth/login-register/otp.vue' /* webpackChunkName: "pages/auth/login-register/otp" */))
const _7f3fa7d9 = () => interopDefault(import('../pages/auth/oauth/oauth-vetc.vue' /* webpackChunkName: "pages/auth/oauth/oauth-vetc" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _434f204f = () => interopDefault(import('../pages/car-manufacturer/_slug.vue' /* webpackChunkName: "pages/car-manufacturer/_slug" */))
const _12d56a6e = () => interopDefault(import('../pages/car-province/_slug.vue' /* webpackChunkName: "pages/car-province/_slug" */))
const _6587f704 = () => interopDefault(import('../pages/cars/_slug.vue' /* webpackChunkName: "pages/cars/_slug" */))
const _35eb7d7f = () => interopDefault(import('../pages/recruit/_slug.vue' /* webpackChunkName: "pages/recruit/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about/",
    component: _0e433b93,
    pathToRegexpOptions: {"strict":true},
    name: "about"
  }, {
    path: "/account/",
    component: _cbd4fa14,
    pathToRegexpOptions: {"strict":true},
    name: "account"
  }, {
    path: "/address/",
    component: _3857e4fa,
    pathToRegexpOptions: {"strict":true},
    name: "address"
  }, {
    path: "/buy-car/",
    component: _b11156da,
    pathToRegexpOptions: {"strict":true},
    name: "buy-car"
  }, {
    path: "/certified/",
    component: _6bc00e26,
    pathToRegexpOptions: {"strict":true},
    name: "certified"
  }, {
    path: "/coming-soon/",
    component: _16c44d4d,
    pathToRegexpOptions: {"strict":true},
    name: "coming-soon"
  }, {
    path: "/compare-car/",
    component: _9dcb461c,
    pathToRegexpOptions: {"strict":true},
    name: "compare-car"
  }, {
    path: "/contact/",
    component: _e64111b4,
    pathToRegexpOptions: {"strict":true},
    name: "contact"
  }, {
    path: "/detail-certified/",
    component: _09b28256,
    pathToRegexpOptions: {"strict":true},
    name: "detail-certified"
  }, {
    path: "/faq/",
    component: _41d65b9f,
    pathToRegexpOptions: {"strict":true},
    name: "faq"
  }, {
    path: "/favorite-cars/",
    component: _28f11594,
    pathToRegexpOptions: {"strict":true},
    name: "favorite-cars"
  }, {
    path: "/inspection/",
    component: _a36d9244,
    pathToRegexpOptions: {"strict":true},
    name: "inspection"
  }, {
    path: "/installment/",
    component: _6cc30cff,
    pathToRegexpOptions: {"strict":true},
    name: "installment"
  }, {
    path: "/login/",
    component: _10ba8d22,
    pathToRegexpOptions: {"strict":true},
    name: "login"
  }, {
    path: "/manufacturer/",
    component: _1a8eea3b,
    pathToRegexpOptions: {"strict":true},
    name: "manufacturer"
  }, {
    path: "/news/",
    component: _3b011b86,
    pathToRegexpOptions: {"strict":true},
    name: "news",
    children: [{
      path: "carpla-events/",
      component: _9bf3b8fa,
      pathToRegexpOptions: {"strict":true},
      name: "news-carpla-events"
    }, {
      path: "news-categories/",
      component: _0ce57e04,
      pathToRegexpOptions: {"strict":true},
      name: "news-news-categories"
    }, {
      path: "result-search-news/",
      component: _27c6b3ea,
      pathToRegexpOptions: {"strict":true},
      name: "news-result-search-news"
    }, {
      path: ":slug?/",
      component: _0ad81490,
      pathToRegexpOptions: {"strict":true},
      name: "news-slug"
    }]
  }, {
    path: "/notifications/",
    component: _5172b96e,
    pathToRegexpOptions: {"strict":true},
    name: "notifications"
  }, {
    path: "/register-success/",
    component: _c24eef7a,
    pathToRegexpOptions: {"strict":true},
    name: "register-success"
  }, {
    path: "/reviews/",
    component: _46ec5cbe,
    pathToRegexpOptions: {"strict":true},
    name: "reviews"
  }, {
    path: "/sales-policies/",
    component: _52fb02db,
    pathToRegexpOptions: {"strict":true},
    name: "sales-policies"
  }, {
    path: "/search-car-v2-result/",
    component: _b094f14c,
    pathToRegexpOptions: {"strict":true},
    name: "search-car-v2-result"
  }, {
    path: "/search-car-v2/",
    component: _143cea60,
    pathToRegexpOptions: {"strict":true},
    name: "search-car-v2"
  }, {
    path: "/search-car/",
    component: _d43acbce,
    pathToRegexpOptions: {"strict":true},
    name: "search-car"
  }, {
    path: "/sell-car/",
    component: _100e737a,
    pathToRegexpOptions: {"strict":true},
    name: "sell-car",
    children: [{
      path: "Step2/",
      component: _1110ee7a,
      pathToRegexpOptions: {"strict":true},
      name: "sell-car-Step2"
    }, {
      path: "Step3/",
      component: _111f05fb,
      pathToRegexpOptions: {"strict":true},
      name: "sell-car-Step3"
    }]
  }, {
    path: "/sitemap/",
    component: _07f8a58a,
    pathToRegexpOptions: {"strict":true},
    name: "sitemap"
  }, {
    path: "/specifications/",
    component: _3c0a7b8c,
    pathToRegexpOptions: {"strict":true},
    name: "specifications"
  }, {
    path: "/thankyou/",
    component: _34199a5f,
    pathToRegexpOptions: {"strict":true},
    name: "thankyou"
  }, {
    path: "/upgrade-car-v2/",
    component: _1774d160,
    pathToRegexpOptions: {"strict":true},
    name: "upgrade-car-v2"
  }, {
    path: "/upgrade-car/",
    component: _00b29329,
    pathToRegexpOptions: {"strict":true},
    name: "upgrade-car"
  }, {
    path: "/account/car-purchase/",
    component: _300b7666,
    pathToRegexpOptions: {"strict":true},
    name: "account-car-purchase"
  }, {
    path: "/account/car-sale/",
    component: _e5f265a8,
    pathToRegexpOptions: {"strict":true},
    name: "account-car-sale"
  }, {
    path: "/account/interested-car/",
    component: _b4387e48,
    pathToRegexpOptions: {"strict":true},
    name: "account-interested-car"
  }, {
    path: "/account/service-247/",
    component: _9c701ef8,
    pathToRegexpOptions: {"strict":true},
    name: "account-service-247"
  }, {
    path: "/auth/change-password/",
    component: _3fdcfe1b,
    pathToRegexpOptions: {"strict":true},
    name: "auth-change-password"
  }, {
    path: "/auth/forgot-password/",
    component: _43fe2188,
    pathToRegexpOptions: {"strict":true},
    name: "auth-forgot-password"
  }, {
    path: "/auth/log-in/",
    component: _0a6dc7ca,
    pathToRegexpOptions: {"strict":true},
    name: "auth-log-in"
  }, {
    path: "/auth/login-register/",
    component: _19af5657,
    pathToRegexpOptions: {"strict":true},
    name: "auth-login-register"
  }, {
    path: "/auth/oauth/",
    component: _22afcc46,
    pathToRegexpOptions: {"strict":true},
    name: "auth-oauth"
  }, {
    path: "/auth/reset-password/",
    component: _0fdb26a6,
    pathToRegexpOptions: {"strict":true},
    name: "auth-reset-password"
  }, {
    path: "/auth/sign-up/",
    component: _7ee727a4,
    pathToRegexpOptions: {"strict":true},
    name: "auth-sign-up"
  }, {
    path: "/blog/list-product-suggest/",
    component: _63bcb34a,
    pathToRegexpOptions: {"strict":true},
    name: "blog-list-product-suggest"
  }, {
    path: "/blog/product-item/",
    component: _2b2e4904,
    pathToRegexpOptions: {"strict":true},
    name: "blog-product-item"
  }, {
    path: "/car-manufacturer/car-brand-year/",
    component: _04177b32,
    pathToRegexpOptions: {"strict":true},
    name: "car-manufacturer-car-brand-year"
  }, {
    path: "/car-manufacturer/car-brand/",
    component: _1308cc93,
    pathToRegexpOptions: {"strict":true},
    name: "car-manufacturer-car-brand"
  }, {
    path: "/car-manufacturer/car-figure/",
    component: _35a8fff0,
    pathToRegexpOptions: {"strict":true},
    name: "car-manufacturer-car-figure"
  }, {
    path: "/cars/Dialog360Interior/",
    component: _9e6dc8e2,
    pathToRegexpOptions: {"strict":true},
    name: "cars-Dialog360Interior"
  }, {
    path: "/cars/DialogCallMe/",
    component: _0fb6f0d8,
    pathToRegexpOptions: {"strict":true},
    name: "cars-DialogCallMe"
  }, {
    path: "/cars/DialogComingSoon/",
    component: _3e75489a,
    pathToRegexpOptions: {"strict":true},
    name: "cars-DialogComingSoon"
  }, {
    path: "/cars/DialogDepositCar/",
    component: _f331b758,
    pathToRegexpOptions: {"strict":true},
    name: "cars-DialogDepositCar"
  }, {
    path: "/cars/DialogInfoOrder/",
    component: _77d9d4b2,
    pathToRegexpOptions: {"strict":true},
    name: "cars-DialogInfoOrder"
  }, {
    path: "/cars/DialogPhoneAndMailCar/",
    component: _3e3758c6,
    pathToRegexpOptions: {"strict":true},
    name: "cars-DialogPhoneAndMailCar"
  }, {
    path: "/cars/DialogPromo/",
    component: _a77aa7fe,
    pathToRegexpOptions: {"strict":true},
    name: "cars-DialogPromo"
  }, {
    path: "/cars/DialogShare/",
    component: _7da84ad1,
    pathToRegexpOptions: {"strict":true},
    name: "cars-DialogShare"
  }, {
    path: "/cars/DialogSimilarCar/",
    component: _6fcdf732,
    pathToRegexpOptions: {"strict":true},
    name: "cars-DialogSimilarCar"
  }, {
    path: "/cars/DialogTestDrive/",
    component: _e5bbacec,
    pathToRegexpOptions: {"strict":true},
    name: "cars-DialogTestDrive"
  }, {
    path: "/cars/Gallery/",
    component: _64285e2c,
    pathToRegexpOptions: {"strict":true},
    name: "cars-Gallery"
  }, {
    path: "/insurance/Component1/",
    component: _6f0b149a,
    pathToRegexpOptions: {"strict":true},
    name: "insurance-Component1"
  }, {
    path: "/insurance/Component10/",
    component: _46cfbb0d,
    pathToRegexpOptions: {"strict":true},
    name: "insurance-Component10"
  }, {
    path: "/insurance/Component2/",
    component: _6eeee598,
    pathToRegexpOptions: {"strict":true},
    name: "insurance-Component2"
  }, {
    path: "/insurance/Component3/",
    component: _6ed2b696,
    pathToRegexpOptions: {"strict":true},
    name: "insurance-Component3"
  }, {
    path: "/insurance/Component4/",
    component: _6eb68794,
    pathToRegexpOptions: {"strict":true},
    name: "insurance-Component4"
  }, {
    path: "/insurance/Component5/",
    component: _6e9a5892,
    pathToRegexpOptions: {"strict":true},
    name: "insurance-Component5"
  }, {
    path: "/insurance/Component6/",
    component: _6e7e2990,
    pathToRegexpOptions: {"strict":true},
    name: "insurance-Component6"
  }, {
    path: "/insurance/Component7/",
    component: _6e61fa8e,
    pathToRegexpOptions: {"strict":true},
    name: "insurance-Component7"
  }, {
    path: "/insurance/Component8/",
    component: _6e45cb8c,
    pathToRegexpOptions: {"strict":true},
    name: "insurance-Component8"
  }, {
    path: "/insurance/insurance/",
    component: _74447d6b,
    pathToRegexpOptions: {"strict":true},
    name: "insurance-insurance"
  }, {
    path: "/insurance/RatingItem/",
    component: _358b41a2,
    pathToRegexpOptions: {"strict":true},
    name: "insurance-RatingItem"
  }, {
    path: "/recruit/DialogSuccessApplication/",
    component: _58e81d70,
    pathToRegexpOptions: {"strict":true},
    name: "recruit-DialogSuccessApplication"
  }, {
    path: "/recruit/recruit/",
    component: _1616781a,
    pathToRegexpOptions: {"strict":true},
    name: "recruit-recruit"
  }, {
    path: "/service/RSA/",
    component: _48cbae68,
    pathToRegexpOptions: {"strict":true},
    name: "service-RSA"
  }, {
    path: "/account/service-247/DialogRequestAssistance/",
    component: _63aee951,
    pathToRegexpOptions: {"strict":true},
    name: "account-service-247-DialogRequestAssistance"
  }, {
    path: "/account/service-247/Service247Item/",
    component: _63b1c21e,
    pathToRegexpOptions: {"strict":true},
    name: "account-service-247-Service247Item"
  }, {
    path: "/auth/login-register/otp/",
    component: _d261c120,
    pathToRegexpOptions: {"strict":true},
    name: "auth-login-register-otp"
  }, {
    path: "/auth/oauth/oauth-vetc/",
    component: _7f3fa7d9,
    pathToRegexpOptions: {"strict":true},
    name: "auth-oauth-oauth-vetc"
  }, {
    path: "/",
    component: _2dfb1658,
    pathToRegexpOptions: {"strict":true},
    name: "index"
  }, {
    path: "/car-manufacturer/:slug?/",
    component: _434f204f,
    pathToRegexpOptions: {"strict":true},
    name: "car-manufacturer-slug"
  }, {
    path: "/car-province/:slug?/",
    component: _12d56a6e,
    pathToRegexpOptions: {"strict":true},
    name: "car-province-slug"
  }, {
    path: "/cars/:slug?/",
    component: _6587f704,
    pathToRegexpOptions: {"strict":true},
    name: "cars-slug"
  }, {
    path: "/recruit/:slug?/",
    component: _35eb7d7f,
    pathToRegexpOptions: {"strict":true},
    name: "recruit-slug"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "Trang chủ"
  }, {
    path: "/dang-nhap",
    component: _19af5657,
    name: "Đăng nhập"
  }, {
    path: "/dang-ky",
    component: _7ee727a4,
    name: "Đăng ký"
  }, {
    path: "/otp",
    component: _d261c120,
    name: "OTP"
  }, {
    path: "/thong-tin-ca-nhan",
    component: _cbd4fa14,
    name: "Thông tin cá nhân"
  }, {
    path: "/mua-xe/:slug",
    component: _434f204f,
    name: "Car manufacturer"
  }, {
    path: "/mua-xe/chi-nhanh/:province",
    component: _12d56a6e,
    name: "Car province"
  }, {
    path: "/mua-xe/kieu-dang/:figure",
    component: _35a8fff0,
    name: "Car figure"
  }, {
    path: "/mua-xe/:slug/:brand",
    component: _1308cc93,
    name: "Car manufacturer brand"
  }, {
    path: "/mua-xe/:slug/:brand/:year",
    component: _04177b32,
    name: "Car brand year"
  }, {
    path: "/xe/:slug",
    component: _6587f704,
    name: "Chi tiết xe"
  }, {
    path: "/hang-xe/:slug",
    component: _1a8eea3b,
    name: "Chi tiết hãng xe"
  }, {
    path: "/tim-xe-v1",
    component: _d43acbce,
    name: "Tìm xe"
  }, {
    path: "/tim-xe",
    component: _143cea60,
    name: "Tìm xe"
  }, {
    path: "/ket-qua-tim-xe",
    component: _b094f14c,
    name: "Kết quả tìm kiếm"
  }, {
    path: "/mua-xe",
    component: _b11156da,
    name: "Mua xe"
  }, {
    path: "/ban-xe",
    component: _100e737a,
    name: "Bán xe"
  }, {
    path: "/doi-xe",
    component: _1774d160,
    name: "Đổi xe"
  }, {
    path: "/ban-xe/otp",
    component: _1110ee7a,
    name: "Bán xe OTP"
  }, {
    path: "/ban-xe/dang-ky-ban-xe-thanh-cong",
    component: _111f05fb,
    name: "Bán xe thành công"
  }, {
    path: "/gioi-thieu",
    component: _0e433b93,
    name: "Giới thiệu"
  }, {
    path: "/chung-nhan-carpla",
    component: _6bc00e26,
    name: "Chứng nhận Carpla"
  }, {
    path: "/thong-so-ky-thuat",
    component: _3c0a7b8c,
    name: "Thông số kỹ thuật"
  }, {
    path: "/chi-tiet-160-diem",
    component: _09b28256,
    name: "Chi tiết 160 điểm kiểm định"
  }, {
    path: "/xe-yeu-thich",
    component: _28f11594,
    name: "Xe yêu thích"
  }, {
    path: "/so-sanh-xe",
    component: _9dcb461c,
    name: "So sánh xe"
  }, {
    path: "/dich-vu/cuu-ho-rsa",
    component: _48cbae68,
    name: "Cứu hộ RSA"
  }, {
    path: "/doi-mat-khau",
    component: _3fdcfe1b,
    name: "Đổi mật khẩu"
  }, {
    path: "/dat-lai-mat-khau",
    component: _0fdb26a6,
    name: "Đặt lại mật khẩu"
  }, {
    path: "/dich-vu/247",
    component: _9c701ef8,
    name: "Dịch vụ 247"
  }, {
    path: "/tin-tuc",
    component: _3b011b86,
    name: "Tin tức"
  }, {
    path: "/tim-kiem-tin-tuc",
    component: _27c6b3ea,
    name: "Tìm kiếm Tin tức"
  }, {
    path: "/su-kien-carpla",
    component: _9bf3b8fa,
    name: "Sự kiện Carpla"
  }, {
    path: "/tuyen-dung",
    component: _1616781a,
    name: "Tuyển dụng"
  }, {
    path: "/tuyen-dung/:slug",
    component: _35eb7d7f,
    name: "Chi tiết tuyển dụng"
  }, {
    path: "/danh-muc-tin-tuc/:slug",
    component: _0ce57e04,
    name: "Danh mục tin tức"
  }, {
    path: "/tin-tuc/:slug",
    component: _0ad81490,
    name: "Tin tức"
  }, {
    path: "/kiem-tra/:slug",
    component: _a36d9244,
    name: "Kiểm tra"
  }, {
    path: "/thong-tin/:slug",
    component: _52fb02db,
    name: "Chính sách bán hàng"
  }, {
    path: "/thong-bao",
    component: _5172b96e,
    name: "Thông báo"
  }, {
    path: "/tai-khoan/lich-su-mua-xe",
    component: _300b7666,
    name: "Lịch sử mua xe"
  }, {
    path: "/tai-khoan/lich-su-ban-xe",
    component: _e5f265a8,
    name: "Lịch sử bán xe"
  }, {
    path: "/tai-khoan/danh-sach-xe-quan-tam",
    component: _b4387e48,
    name: "Danh sách xe quan tam"
  }, {
    path: "/sitemap",
    component: _07f8a58a,
    name: "Sitemap"
  }, {
    path: "/oauth",
    component: _22afcc46,
    name: "Oauth"
  }, {
    path: "/dang-ky-thanh-cong",
    component: _c24eef7a,
    name: "Đăng ký thành công"
  }, {
    path: "/danh-gia",
    component: _46ec5cbe,
    name: "Đánh giá"
  }, {
    path: "/vi-tri",
    component: _3857e4fa,
    name: "Vị trí"
  }, {
    path: "/lien-he",
    component: _e64111b4,
    name: "Liên hệ"
  }, {
    path: "/tra-gop",
    component: _6cc30cff,
    name: "Trả góp"
  }, {
    path: "/faq",
    component: _41d65b9f,
    name: "FAQ"
  }, {
    path: "/blog-car",
    component: _63bcb34a,
    name: "Blog list cars"
  }, {
    path: "/thankyou",
    component: _34199a5f,
    name: "Thank you"
  }, {
    path: "/bao-hiem-tasco",
    component: _74447d6b,
    name: "Bảo Hiểm"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
